import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Benasque - in the north of Spain - is the starting point for many hikers to
climb the 3404m high Pico de Aneto, the highest peak of the Pyrenees.`}</p>
    <br />
    <p>{`Benasque is also one of the best mtb destinations with 18 marked enduro loops
(and 12 marathon). The diversity of the trails is like the richness of
color in the beautiful landscape. From the black of the Sierra Negra,
over the red rocks of the Gallinero, to the green of the forests down in
valley.`}</p>
    <br />
    <p>{`The local mountain bike club Puro Pirineo takes care of the trails
and that works really well. Good signage, well maintained and endless
possibilities. In the quiet valley you have good touristic infrastructure,
accomodations, guiding and shuttle services.`}</p>
    <br />
    <p>{`The best time to go there is in
summer from May to November. Benasque is on 1.200m so there is lots of snow
in winter time, but perfect temperatures during summer.`}</p>
    <TrailguidePlugin content="lat=42.5518&lng=0.4950" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,42.5518,0.4950"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Gallinero`}</h4>
    <p>{`Lets start with our favourite trail. Here you see the unique red rock
section in the middle part.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2479"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/benasque_3.jpg" mdxType="Image" />
    <br />
    <p>{`It's one of the longest trails around Benasque.`}</p>
    <Image src="destinations/benasque_1.jpg" mdxType="Image" />
    <br />
    <p>{`Good mix of technical and flowy sections. The rocks dominate the upper part.
In the woods there is more dirt.`}</p>
    <Image src="destinations/benasque_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Rabaltueras`}</h4>
    <p>{`With a view on the high and snowy peaks of the Pyrenees.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2478"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/benasque_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Magic line`}</h4>
    <p>{`Magic light on the Magic line.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2473"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/benasque_6.jpg" mdxType="Image" />
    <br />
    <p>{`On the south side of the valley, the terrain is less dry with more dirt and roots.`}</p>
    <Image src="destinations/benasque_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Rabaltueras`}</h4>
    <p>{`In some parts it even like in the jungle.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2478"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/benasque_5.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      